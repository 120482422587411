/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-phone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Calling from 'embercom/models/settings/calling';
import type CardlessTrialService from 'embercom/services/cardless-trial-service';

interface Args {
  settings: Calling;
  isCallingSupported: boolean;
  isFromNewSettings?: boolean;
}

export default class CallingSettings extends Component<Args> {
  @service declare cardlessTrialService: CardlessTrialService;

  get trialCreditBalance() {
    return Number(this.args.settings.trialCreditBalance).toFixed(2);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings': typeof CallingSettings;
    'calling/settings': typeof CallingSettings;
  }
}
