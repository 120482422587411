/* import __COLOCATED_TEMPLATE__ from './phone-number-modal.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import type Store from '@ember-data/store';
import type ApplicationInstance from '@ember/application/instance';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type PhoneNumber from 'embercom/models/calling-phone-number';
import { PhoneNumberStatus } from 'embercom/models/calling-phone-number';
import {
  PHONE_NUMBER_COUNTRY_DETAILS,
  countryNeedsBundleForNumberType,
} from 'embercom/models/settings/calling';
import type IntlService from 'embercom/services/intl';

interface Args {
  phoneNumber: PhoneNumber;
  closeModal: () => void;
}

interface Signature {
  Args: Args;
}

export default class PhoneNumberModal extends Component<Signature> {
  readonly missingBundle = PhoneNumberStatus.MissingBundle;

  @service declare appService: any;
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked showDeleteModal = false;
  @tracked selectedRegulatoryBundle?: string;

  get phoneNumberFlag() {
    return PHONE_NUMBER_COUNTRY_DETAILS.get(this.args.phoneNumber.countryIsoCode)?.flag;
  }

  get noTeamsForApp() {
    return this.appService.app.assignableTeams.length === 0;
  }

  get listOfAssignableTeams() {
    let teams = this.appService.app.assignableTeams;
    let options = teams.map((team: any) => {
      return {
        text: team.get('name'),
        value: team.get('id'),
        component: 'common/avatar-and-name-dropdown-item',
        componentAttrs: {
          model: team,
          modelDisplayName: team.get('name'),
          avatarSize: 'xs',
        },
      };
    });

    return options;
  }

  get shouldShowVoicemailSettings() {
    return this.args.phoneNumber.routingMethod !== 'workflow';
  }

  get needsRegulatoryBundle() {
    return countryNeedsBundleForNumberType(
      this.args.phoneNumber.countryIsoCode,
      this.args.phoneNumber.phoneType,
    );
  }

  get countryCode() {
    return this.args.phoneNumber.countryIsoCode;
  }

  get phoneType() {
    return this.args.phoneNumber.phoneType;
  }

  get regulatoryBundleSid() {
    return this.args.phoneNumber.regulatoryBundleSid;
  }

  get phoneNumberStatus() {
    return this.args.phoneNumber.status;
  }

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);

    if (this.noTeamsForApp) {
      this.args.phoneNumber.assignTeamId = '0';
    } else {
      this.args.phoneNumber.assignTeamId =
        this.args.phoneNumber.assignTeamId || this.listOfAssignableTeams[0]?.value;
    }
  }

  openPortingTicket() {
    window.Intercom('showTicket', this.args.phoneNumber.portingTicketId);
  }

  @action
  toggleOutboundCalls() {
    this.args.phoneNumber.outboundEnabled = !this.args.phoneNumber.outboundEnabled;
  }

  @action
  toggleInboundCalls() {
    this.args.phoneNumber.inboundEnabled = !this.args.phoneNumber.inboundEnabled;
  }

  @action selectRegulatoryBundle(regulatoryBundleSid: string) {
    this.args.phoneNumber.regulatoryBundleSid = regulatoryBundleSid;
  }

  @action
  savePhoneNumberSettings() {
    this.args.phoneNumber.save();
    this.args.closeModal();
  }

  @action
  deletePhoneNumber() {
    this.args.phoneNumber.destroyRecord();
    this.args.closeModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Calling::Settings::PhoneNumberModal': typeof PhoneNumberModal;
    'calling/settings/phone-number-modal': typeof PhoneNumberModal;
  }
}
