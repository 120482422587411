/* import __COLOCATED_TEMPLATE__ from './icon-selector-popover-component.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { collectionIcons, collectionIconsKeywords } from 'embercom/models/data/article/constants';

class IconGroup {
  id: string;
  titleKey: string;
  icons: string[];

  constructor(id: string, titleKey: string, icons: string[]) {
    this.id = id;
    this.titleKey = titleKey;
    this.icons = icons.map((item: string) => `${id}-${item}`);
  }
}

interface Args {
  folder: $TSFixMe;
  uploadHelper: $TSFixMe;
  action: () => void;
}

interface Signature {
  Args: Args;
  Blocks: any;
}

export default class IconSelectorPopoverComponent extends Component<Signature> {
  @service appService: any;
  @service intl: any;

  @tracked selectedIcon = null;
  @tracked searchTerm = '';

  @action
  selectIcon(iconIdentifier: string) {
    this.args.folder.icon = iconIdentifier;
    if (this.args.folder.externalIconUrl) {
      this.args.uploadHelper.externalIcon.setProperties({
        isReset: true,
        isSaved: false,
        url: '',
      });
      this.args.folder.externalIconUrl = null;
    }

    if (this.args.action) {
      this.args.action();
    }
  }

  get filteredIconGroups() {
    // find term on both icon name and keywords associated with it
    return this.iconGroups
      .map((iconGroup) => {
        let filteredIcons = iconGroup.icons.filter(
          (icon) =>
            icon.includes(this.searchTerm) ||
            collectionIconsKeywords.doesTermMatchIconKeywords(
              icon.replace(`${iconGroup.id}-`, ''),
              this.searchTerm,
              this.intl,
            ),
        );
        if (filteredIcons.length === 0) {
          return null;
        }

        return {
          ...iconGroup,
          icons: filteredIcons,
        };
      })
      .filter((iconGroup) => iconGroup !== null);
  }

  get iconGroups() {
    return [
      new IconGroup(
        'arr-sym',
        'articles.collections.icons.arr-sym',
        collectionIcons.ARROW_SYMBOLS_ICONS,
      ),
      new IconGroup(
        'bizz-fin',
        'articles.collections.icons.bizz-fin',
        collectionIcons.BIZZ_FIN_ICONS,
      ),
      new IconGroup('charts', 'articles.collections.icons.charts', collectionIcons.CHARTS_ICONS),
      new IconGroup(
        'communication',
        'articles.collections.icons.communication',
        collectionIcons.COMMUNICATION_ICONS,
      ),
      new IconGroup('content', 'articles.collections.icons.content', collectionIcons.CONTENT_ICONS),
      new IconGroup('ff', 'articles.collections.icons.ff', collectionIcons.FF_ICONS),
      new IconGroup('nature', 'articles.collections.icons.nature', collectionIcons.NATURE_ICONS),
      new IconGroup('other', 'articles.collections.icons.other', collectionIcons.OTHER_ICONS),
      new IconGroup(
        'other-devices',
        'articles.collections.icons.other-devices',
        collectionIcons.OTHER_DEVICES_ICONS,
      ),
      new IconGroup(
        'people-chat-gets',
        'articles.collections.icons.people-chat-gets',
        collectionIcons.PEOPLE_CHAT_GETS_ICONS,
      ),
      new IconGroup('sft', 'articles.collections.icons.sft', collectionIcons.SFT_ICONS),
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'articles/site/collections/icon-selector-popover-component': typeof IconSelectorPopoverComponent;
    'Articles::Site::Collections::IconSelectorPopoverComponent': typeof IconSelectorPopoverComponent;
  }
}
