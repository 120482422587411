/* import __COLOCATED_TEMPLATE__ from './admin-state-stamp-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import Component from '@glimmer/component';
import type AdminSeatMappingFragment from 'embercom/models/billing/migration-admin-seat-mapping';
import type Admin from 'embercom/models/admin';
import {
  PRICING_5_X_LITE_SEAT_TYPE as LITE,
  COPILOT_SEAT_TYPE as COPILOT,
} from 'embercom/lib/settings/seats/constants';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { PRICING_5_X_CORE_ESSENTIAL_ID } from 'embercom/lib/billing';

interface Args {
  adminSeatMapping: AdminSeatMappingFragment;
  selectedPlanId: string;
  admins: Admin[];
  isActive: boolean;
}

interface Signature {
  Args: Args;
}

export default class AdminStateStampCell extends Component<Signature> {
  @service declare intl: IntlService;

  get isEssentialPlanSelected() {
    return this.args.selectedPlanId === PRICING_5_X_CORE_ESSENTIAL_ID;
  }

  get hasExistingCopilotSeat() {
    return this.admin?.seats.includes(COPILOT);
  }

  get assigningLiteSeat() {
    if (this.isEssentialPlanSelected) {
      return false;
    }
    return this.args.adminSeatMapping.seatTypes.includes(LITE);
  }

  get admin() {
    return this.args.admins.find(
      (admin: Admin) => admin.id === this.args.adminSeatMapping.adminId.toString(),
    );
  }

  get tooltipContent() {
    if (!this.args.isActive) {
      return this.intl.t(
        'billing.migrations.edit_plan.admin_seat_mapping.body.stamps.tooltips.inactive',
        { htmlSafe: true },
      );
    }
    if (this.hasExistingCopilotSeat) {
      return !this.assigningLiteSeat
        ? this.intl.t(
            'billing.migrations.edit_plan.admin_seat_mapping.body.stamps.tooltips.copilot',
          )
        : this.intl.t(
            'billing.migrations.edit_plan.admin_seat_mapping.body.stamps.tooltips.revoking_copilot',
          );
    }
    return '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Billing::StripeMigration::SeatAssignment::AdminStateStampCell': typeof AdminStateStampCell;
  }
}
