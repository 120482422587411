/* import __COLOCATED_TEMPLATE__ from './success.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { CheckoutType } from 'embercom/routes/apps/app/checkout/success';
import { BILLING_PERIODS, PLAN_DATA } from 'embercom/lib/billing';
import moment from 'moment-timezone';

const DAYS_BEFORE_TRIAL_END_FOR_REMINDER = 2;

export interface Args {
  checkoutType: CheckoutType;
  planId: string;
  firstInvoicedOnDate: moment.Moment;
  billingPeriod: BILLING_PERIODS;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class Success extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: any;

  get plan() {
    return PLAN_DATA[this.args.planId];
  }

  get heading() {
    switch (this.args.checkoutType) {
      case CheckoutType.EarlyStagePostPurchase:
        return this.t('checkout.success.early_stage_post_purchase.heading');
      case CheckoutType.ACHPostPurchase:
        return this.t('checkout.success.ach_post_purchase.heading');
      case CheckoutType.ACHPostAnnualMigration:
        return this.t('checkout.success.ach_post_annual_migration.heading');
      case CheckoutType.DirectCheckoutPostPurchase:
        return this.t('checkout.success.direct_checkout_post_purchase.heading');
      case CheckoutType.DirectCheckoutPostAnnualMigration:
        return this.t('checkout.success.direct_checkout_post_annual_migration.heading');
      case CheckoutType.OneDollarTrialPostStart:
        return this.t('checkout.success.one_dollar_trial_post_start.heading');
    }
  }

  get subheading() {
    switch (this.args.checkoutType) {
      case CheckoutType.EarlyStagePostPurchase:
        return this.t('checkout.success.early_stage_post_purchase.subheading');
      case CheckoutType.ACHPostPurchase:
        return this.t('checkout.success.ach_post_purchase.subheading');
      case CheckoutType.ACHPostAnnualMigration:
        return this.t('checkout.success.ach_post_annual_migration.subheading');
      case CheckoutType.DirectCheckoutPostPurchase:
        return this.t('checkout.success.direct_checkout_post_purchase.subheading');
      case CheckoutType.DirectCheckoutPostAnnualMigration:
        return this.t('checkout.success.direct_checkout_post_annual_migration.subheading');
      case CheckoutType.OneDollarTrialPostStart:
        return this.t('checkout.success.one_dollar_trial_post_start.subheading');
    }
  }

  get body() {
    switch (this.args.checkoutType) {
      case CheckoutType.EarlyStagePostPurchase:
        return this.earlyStagePostPurchaseBody;
      case CheckoutType.ACHPostPurchase:
        return this.achPostPurchaseBody;
      case CheckoutType.ACHPostAnnualMigration:
        return this.achPostAnnualMigrationBody;
      case CheckoutType.DirectCheckoutPostPurchase:
        return this.directCheckoutPostPurchaseBody;
      case CheckoutType.DirectCheckoutPostAnnualMigration:
        return this.directCheckoutPostAnnualMigrationBody;
      case CheckoutType.OneDollarTrialPostStart:
        return this.oneDollarTrialPostStartBody;
    }
  }

  private get earlyStagePostPurchaseBody() {
    return [
      {
        heading: this.t('checkout.success.early_stage_post_purchase.body.now.heading'),
        content: this.t('checkout.success.early_stage_post_purchase.body.now.content'),
      },
      {
        heading: this.t(
          'checkout.success.early_stage_post_purchase.body.subscription_starts.heading',
        ),
        content: this.t(
          'checkout.success.early_stage_post_purchase.body.subscription_starts.content',
        ),
      },
      {
        heading: this.t(
          'checkout.success.early_stage_post_purchase.body.subscription_continues_year_two.heading',
        ),
        content: this.t(
          'checkout.success.early_stage_post_purchase.body.subscription_continues_year_two.content',
        ),
      },
      {
        heading: this.t(
          'checkout.success.early_stage_post_purchase.body.subscription_continues_year_three.heading',
        ),
        content: this.t(
          'checkout.success.early_stage_post_purchase.body.subscription_continues_year_three.content',
        ),
      },
    ];
  }

  private get achPostPurchaseBody() {
    return [
      {
        heading: this.t('checkout.success.ach_post_purchase.body.now.heading'),
        content: this.t('checkout.success.ach_post_purchase.body.now.content'),
      },
      {
        heading: this.t(
          'checkout.success.ach_post_purchase.body.within_four_business_days.heading',
        ),
        content: this.t(
          'checkout.success.ach_post_purchase.body.within_four_business_days.content',
        ),
      },
      {
        heading: this.t('checkout.success.ach_post_purchase.body.subscription_renews.heading'),
        content: this.t('checkout.success.ach_post_purchase.body.subscription_renews.content'),
      },
    ];
  }

  private get achPostAnnualMigrationBody() {
    return [
      {
        heading: this.t('checkout.success.ach_post_annual_migration.body.now.heading'),
        content: this.t('checkout.success.ach_post_annual_migration.body.now.content'),
      },
      {
        heading: this.t(
          'checkout.success.ach_post_annual_migration.body.within_four_business_days.heading',
        ),
        content: this.t(
          'checkout.success.ach_post_annual_migration.body.within_four_business_days.content',
        ),
      },
      {
        heading: this.t(
          'checkout.success.ach_post_annual_migration.body.subscription_renews.heading',
        ),
        content: this.t(
          'checkout.success.ach_post_annual_migration.body.subscription_renews.content',
        ),
      },
    ];
  }

  private get directCheckoutPostPurchaseBody() {
    return [
      {
        heading: this.t('checkout.success.direct_checkout_post_purchase.body.now.heading'),
        content: this.t('checkout.success.direct_checkout_post_purchase.body.now.content'),
      },
      {
        heading: this.t('checkout.success.direct_checkout_post_purchase.body.in_one_hour.heading'),
        content: this.t('checkout.success.direct_checkout_post_purchase.body.in_one_hour.content'),
      },
      {
        heading: this.t(
          'checkout.success.direct_checkout_post_purchase.body.subscription_renews.heading',
        ),
        content: this.t(
          'checkout.success.direct_checkout_post_purchase.body.subscription_renews.content',
        ),
      },
    ];
  }

  private get directCheckoutPostAnnualMigrationBody() {
    return [
      {
        heading: this.t('checkout.success.direct_checkout_post_annual_migration.body.now.heading'),
        content: this.t('checkout.success.direct_checkout_post_annual_migration.body.now.content'),
      },
      {
        heading: this.t(
          'checkout.success.direct_checkout_post_annual_migration.body.in_one_hour.heading',
        ),
        content: this.t(
          'checkout.success.direct_checkout_post_annual_migration.body.in_one_hour.content',
        ),
      },
      {
        heading: this.t(
          'checkout.success.direct_checkout_post_annual_migration.body.subscription_renews.heading',
        ),
        content: this.t(
          'checkout.success.direct_checkout_post_annual_migration.body.subscription_renews.content',
        ),
      },
    ];
  }

  private get oneDollarTrialPostStartBody() {
    return [
      {
        heading: this.t('checkout.success.one_dollar_trial_post_start.body.now.heading'),
        content: this.t('checkout.success.one_dollar_trial_post_start.body.now.content'),
      },
      {
        heading: this.t('checkout.success.one_dollar_trial_post_start.body.trial_reminder.heading'),
        content: this.t('checkout.success.one_dollar_trial_post_start.body.trial_reminder.content'),
      },
      {
        heading: this.t(
          'checkout.success.one_dollar_trial_post_start.body.subscription_starts.heading',
        ),
        content: this.t(
          'checkout.success.one_dollar_trial_post_start.body.subscription_starts.content',
        ),
      },
    ];
  }

  private t(key: string) {
    return this.intl.t(key, this.translationProps);
  }

  private get translationProps() {
    return {
      planName: this.plan.name,
      billingPeriod: this.args.billingPeriod,
      yearlyBilling: this.args.billingPeriod === BILLING_PERIODS.Annual,
      firstInvoicedOnDate: this.formatDate(this.firstInvoicedOnDate),
      subscriptionRenewsOnDate: this.formatDate(this.subscriptionRenewsOnDate),
      trialLengthInDays: this.appService.app.trialLengthInDays,
      daysBeforeTrialEndToRemind: this.daysBeforeTrialEndToRemind,
      remindedOfTrialEndOn: this.formatDate(this.remindedOfTrialEndOn),
      subscriptionContinuesOnDateYearTwo: this.formatDate(this.subscriptionContinuesOnDate(1)),
      subscriptionContinuesOnDateYearThree: this.formatDate(this.subscriptionContinuesOnDate(2)),
    };
  }

  private get daysBeforeTrialEndToRemind() {
    return DAYS_BEFORE_TRIAL_END_FOR_REMINDER;
  }

  private get firstInvoicedOnDate() {
    return this.args.firstInvoicedOnDate;
  }

  private get subscriptionRenewsOnDate() {
    return moment(this.firstInvoicedOnDate).add(
      1,
      this.args.billingPeriod === BILLING_PERIODS.Annual ? 'year' : 'month',
    );
  }

  private subscriptionContinuesOnDate(endOfYear: number) {
    return moment(this.firstInvoicedOnDate).add(endOfYear, 'year');
  }

  private get remindedOfTrialEndOn() {
    return moment(this.firstInvoicedOnDate).subtract(this.daysBeforeTrialEndToRemind + 1, 'day');
  }

  private dateFormat() {
    switch (this.args.checkoutType) {
      case CheckoutType.ACHPostPurchase:
      case CheckoutType.ACHPostAnnualMigration:
      case CheckoutType.DirectCheckoutPostPurchase:
      case CheckoutType.OneDollarTrialPostStart:
        return {
          day: 'numeric',
          month: 'long',
        };
      case CheckoutType.EarlyStagePostPurchase:
      case CheckoutType.DirectCheckoutPostAnnualMigration:
        return {
          day: 'numeric',
          month: 'long',
          year: 'numeric',
        };
    }
  }

  private formatDate(date: moment.Moment) {
    return this.intl.formatDate(date, this.dateFormat());
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Checkout::Success': typeof Success;
    'checkout/success': typeof Success;
  }
}
